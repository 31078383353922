/**
 * @fileoverview App.js is the main entry point for the application. It contains the routing logic for the application.
 * 
 * 
 * 
 */

import "./App.css";
import React, { useEffect, useLayoutEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./pages/Login.js";
import Dashboard from "./pages/Dashboard.js";
import Admin from "./pages/CreateAccount.js";
import AdminLogin from "./pages/AdminLogin";
import useAuthStore from "./store/useAuthStore.js";
import Phases from "./pages/Phases.js";
import YearOverYear from "./pages/YearOverYear.js";
import usePhases from "./store/usePhases.js";
import Navbar from "./components/Navbar";
import FleetEditor from "./pages/FleetEditor";
import AdminSetDefaults from "./pages/AdminSetDefaults";
import ProtectedRoute from "./components/admin-page/ProtectedRoute";
import AdminDeleteUser from "./pages/AdminDeleteUser";
import AdminNav from "./components/admin-page/AdminNav";
import AdminSetInfo from "./pages/AdminSetInfo";
import useProForma from "./store/useProForma";
import SetPassword from "./pages/SetPassword";
import { Toaster } from "./components/ui/toaster";
import getScrollbarWidth from "./getScrollbarWidth";
import useBodyScrollable from "./useBodyScrollable";
import useNavState from "./store/useNavState";
import { useMediaQuery } from "react-responsive";
import AdminRecoverUser from "./pages/AdminRecoverUser";
import ForgotPassword from "./pages/ForgotPassword";

/**
 * 
 * @function MainLayout - This component is the main layout for the application. It contains the navigation bar and the main content of the application.
 */


const MainLayout = ({ children }) => {
  const { isOpen } = useNavState();
  const { user, loading } = useAuthStore();

  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

  const navigate = useNavigate();
  useEffect(() => {
    if (!user && !loading) {
      navigate("/login");
    }
  }, [user, loading, navigate]);

  return (
    <div className="flex h-full w-full bg-background_gray">
      {!isMobile && (
      <div className={`${isOpen ? 'w-[250px]' : 'w-[75px]'} z-50 bg-background_gray`}>
        <Navbar />
      </div>
    )}
      {isMobile ? (
        <div className="w-full h-full flex-grow bg-background_gray">
          {children}
        </div>
      ) : (
        <div className="w-1/2 h-full flex-grow bg-background_gray">
          {children}
        </div>
      )}
    </div>
  );
};

/**
 * 
 * @function AdminLayout - This component is the layout for the admin pages. It contains the navigation bar and the main content of the admin pages.
 */
const AdminLayout = ({ children }) => {
  return (
    <div className="w-full">
      <div className="w-full h-[80px] flex items-center justify-center fixed top-0 left-0 right-0 z-50 bg-white border-b border-gray-200">
        <AdminNav />
      </div>
      <div className="pt-[100px] p-10 h-full">{children}</div>
    </div>
  );
};

function App() {
  const { initializeAuth, user, data, session } = useAuthStore();
  const { phases } = usePhases();

  const { fetchAndUpdateFleet } = useProForma();


  // Fetch fleet data on user login
  useEffect(() => {
    fetchAndUpdateFleet();
  }, [user]);

  // Initialize auth on app load
  useEffect(() => {
    initializeAuth();
  }, [initializeAuth]);

  const scrollbarWidth = getScrollbarWidth();
  const bodyScrollable = useBodyScrollable();
  useLayoutEffect(() => {
    if (bodyScrollable) {
      document.body.style.paddingRight = "0px";
    } else {
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    }
  }, [bodyScrollable]);

  return (
    <div className="bg-background_gray min-h-screen">
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout>
              <Dashboard />
            </MainLayout>
          }
        />
        <Route
          path="/phases"
          element={
            <MainLayout>
              <Phases />
            </MainLayout>
          }
        />
        <Route
          path="/yearoveryear"
          element={
            <MainLayout>
              <YearOverYear />
            </MainLayout>
          }
        />
        <Route
          path="/fleet"
          element={
            <MainLayout>
              <FleetEditor />
            </MainLayout>
          }
        />
        <Route
          path="/set-defaults"
          element={
            <div className="w-full">
              <AdminLayout>
                <AdminSetDefaults />
              </AdminLayout>
            </div>
          }
        />
        <Route
          path="/set-info"
          element={
            <div className="w-full">
              <AdminLayout>
                <AdminSetInfo />
              </AdminLayout>
            </div>
          }
        />
        <Route
          path="/delete-user"
          element={
            <ProtectedRoute route="/delete-user">
              <AdminLayout>
                <AdminDeleteUser />
              </AdminLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recover-user"
          element={
            <ProtectedRoute route="/recover-user">
              <AdminLayout>
                <AdminRecoverUser />
              </AdminLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <ProtectedRoute route="/admin">
              <AdminLayout>
                <Admin />
              </AdminLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
              <ForgotPassword />
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/set-password" element={<SetPassword />} />
      </Routes>
      <Toaster />
    </div>
  );
}

export default App;
