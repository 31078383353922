/**
 * Component for the login form
 * 
 * Parent: pages/Login.js
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useState } from "react";
import useAuthStore from "store/useAuthStore";
import { EyeNoneIcon, EyeOpenIcon } from "@radix-ui/react-icons"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";

const LoginForm = () => {
  const { login } = useAuthStore();
  const navigate = useNavigate();


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const newEmail = email + "@optonyusa.com";
    console.log(newEmail,password);
    try {
      const loginStatus = await login(newEmail, password);

      if (null == loginStatus) {
        setMessage("Invalid email or password");
        
      } else if (!loginStatus.firstLogin) {
        navigate("/");
        
      } else if (loginStatus.firstLogin) {
        navigate("/set-password");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <Card className="h-full p-4">
      <CardHeader className="pb-14">
        <CardTitle className="text-optonyprimary">
          Pro Forma User Access Login
        </CardTitle>
        <CardDescription>
          Enter your username and password below to login to your account
        </CardDescription>
        <p className="text-sm text-red-400">{message}</p>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleLogin}>
          <div className="grid w-full items-center gap-4">
            <div className="flex flex-col space-y-1.5">
              <Input
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Username"
                value={email}
                name="username"
              />
            </div>
            <div className="relative flex flex-col space-y-1.5">
              <Input
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-[6px] text-gray-600 hover:text-black"
              >
                {showPassword ?  <EyeOpenIcon />:<EyeNoneIcon />}
              </button>
            </div>
            <div className="flex flex-col space-y-1.5">
              <Button type="submit">Login</Button>
            </div>
          </div>
        </form>
        <p className="text-optonyprimary hover:text-optonyprimaryhover text-sm underline mt-2"><a href="/forgot-password">Forgot Password?</a></p>
      </CardContent>
    </Card>
  );
};

export default LoginForm;
